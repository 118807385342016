.sortable-chosen-custom {
  background-color: #f5f5f5;
}

.sortable-drag-custom {
  background-color: #f5f5f5;
  width: 40vw;
  padding: 1.2rem;
}

.sortable-ghost-custom
{
  width: 100%;
  padding: 2.3rem;
}