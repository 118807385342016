.headerContainer { 
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.actions { 
  display: flex;
  align-items: center;
  gap: 0.5rem;
}