.rowContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.2px solid #ededed;
  padding: 0.4rem 1rem;
  color: #a3a3a3;
}

.rowContainer:last-of-type {
  border-bottom: none;
}

.rowContainer i {
  padding: 1rem;
  font-size: 1.4rem;
  color: #c9e0ff;
}

.filename {
  justify-content: start;
  color: #000
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: end;
}